import React, { useState, useEffect, useRef } from 'react';
import { TextField, IconButton, Avatar, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import AddCommentIcon from '@mui/icons-material/AddComment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AndroidIcon from '@mui/icons-material/Android'; // Robot icon
import CircularProgress from '@mui/material/CircularProgress';

const FirmamentoAi = () => {
  const [messages, setMessages] = useState([{ sender: 'ai', text: 'Hello! How can I help you today?' }]);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);

  const messagesEndRef = useRef(null);
  const textboxRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(() => {
    // Check if the textboxRef is currently linked to an element
    if (textboxRef.current) {
      // If so, focus the element
      textboxRef.current.focus();
    } else {
      // If not, wait for a bit and then try to focus again
      // This can help in situations where the element might not be immediately available
      const focusTimeout = setTimeout(() => {
        if (textboxRef.current) {
          textboxRef.current.focus();
        }
      }, 100); // Reduced the timeout to 100ms

      // Clear the timeout if the component unmounts
      return () => clearTimeout(focusTimeout);
    }
  }, []); // Dependency array is still empty to mimic componentDidMount behavior

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const sendMessage = async () => {
    if (loading) {
      alert('Please wait for the AI to respond before sending another message.');
      return;
    } else {
      setLoading(true);
    }
    if (inputValue.trim()) {
      // Add user message
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: 'user', text: inputValue },
      ]);
      let toSend = inputValue;
      // Clear input field
      setInputValue('');
      try {

        // Send POST request using fetch
        const response = await fetch('https://firmamento-nyuad-25ebad56567d.herokuapp.com/firmamentoai', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ message: toSend }),
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        // Add AI message
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: 'ai', text: data.message },
        ]);
        setLoading(false);
      } catch (error) {
        console.error('Error sending message:', error);
      }
      // Clear input field
      setInputValue('');
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      sendMessage();
    }
  };

  return (
    <div className='textcolorwhite'>
      <div className='alksjfislfds'>
        <List>
          {messages.map((msg, index) => (
            <ListItem key={index}>
              <ListItemAvatar>
                <Avatar className={(msg.sender === 'user') ? '' : 'aiBg'}>
                  {msg.sender === 'user' ? <AccountCircleIcon /> : <AndroidIcon />}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={msg.text} />
            </ListItem>
          ))}
        </List>
        {loading && <div className='alignCenterx'> <CircularProgress color="primary" size={28} thickness={8} disableShrink={true} /> </div>}
        <div ref={messagesEndRef} />
      </div>
      <div className='inputProvider'>
        <TextField
          fullWidth
          autoFocus={true}
          inputRef={textboxRef}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Type your message here..."
        />
        <div className='displayvertically'>
          <IconButton onClick={sendMessage}>
            <SendIcon />
          </IconButton>
          <IconButton onClick={() => { setMessages([{ sender: 'ai', text: 'Hello! How can I help you today?' }]) }}>
            <AddCommentIcon />
          </IconButton>

        </div>

      </div>

    </div>
  );
};

export default FirmamentoAi;
