import React, { Suspense, useEffect } from 'react';
import { useLocation, Route, Switch, Redirect } from 'react-router-dom';
import Layout from './components/Layout';
import CircularProgress from '@mui/material/CircularProgress';

const HomePage = React.lazy(() => import('./homepg/HomePage'));
const LoginPage = React.lazy(() => import('./loginpg/Login'));
const LoggedIn = React.lazy(() => import('./loginpg/LoggedIn'));
const Feedbackpage = React.lazy(() => import('./otherpages/FeedbackPage'));
const ImagePage = React.lazy(() => import('./mediapg/ImagePage'));
const VouSoftware = React.lazy(() => import('./softwarepg/VouSoftware'));
const ResourcePage = React.lazy(() => import('./resourcespg/ResourcePage'));

// makes sure whenever other pages are navigated to, the page always scrolls to top
function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

function App() {
  return (
    <Layout className="App">
      <ScrollToTop />
      <Suspense
        fallback={
          <div className='centered'>
            <CircularProgress disableShrink />
          </div>
        }
      >
        <Switch>
          <Route path='/' exact>
            <Redirect to='/home' />
          </Route>
          <Route path='/home'>
            <HomePage />
          </Route>
          <Route path='/login'>
            <LoginPage />
          </Route>
          <Route path='/loggedin'>
            <LoggedIn />
          </Route>
          <Route path='/feedback'>
            <Feedbackpage />
          </Route>
          <Route path='/media'>
            <ImagePage />
          </Route>
          <Route path='/data_access'>
            <VouSoftware />
          </Route>

          <Route path='/resources'>
            <ResourcePage />
          </Route>
          <Route path='*'>
            <Redirect to='/home' />
          </Route>
        </Switch>
      </Suspense>
    </Layout>
  );
}

export default App;
