import { Fragment, useEffect, useState } from 'react';
import { Nav, Navbar, NavbarBrand, NavbarToggler, Collapse, Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import logoImg from '../assets/logo1.jpg';
import fullLogo from '../assets/logo.png';
import Drawer from '@mui/material/Drawer';
import FirmamentoAi from '../softwarepg/firmamentoai';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import logoImg2 from '../assets/cap3logo.png';
import adlogo from '../assets/nyuadlogo.png';

const Layout = (props) => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isModelOpen, setModelOpen] = useState(false);

  const toggleNav = () => {
    if (document.documentElement.clientWidth < 769) {
      setIsNavOpen(!isNavOpen);
    }
  };

  const [userName, setUserName] = useState('');
  const drawerWidth = 500;
  const [open, setOpen] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const usrnm = window.localStorage.getItem('userName');
    if (usrnm) {
      setUserName(usrnm);
    } else {
      setUserName('Login');
    }

  }, []);

  return (
    <Fragment>
      <div>
        <Navbar dark className="headbg" expand="md">
          <div className="container">
            <NavbarBrand className="navlogo" href="/">
              <img className="logoImg smallLogo" src={logoImg} height='46' width='38' alt='LOGO' />
              <img className="logoImg fullLogo" src={fullLogo} height='40' width='128' alt='LOGO' />
            </NavbarBrand>
            <NavbarToggler onClick={toggleNav} className="mr-1" />

            <Collapse isOpen={isNavOpen} navbar>
              <Nav navbar className="collapsebg me-auto">

                <NavLink className="nav-link navItm" to="/home" onClick={toggleNav}>HOME
                </NavLink>

                <NavLink className="nav-link navItm" to="/data_access" onClick={toggleNav}> DATA ACCESS
                </NavLink>

                <NavLink className="nav-link navItm" to="/resources" onClick={toggleNav}> RESOURCES
                </NavLink>

                <NavLink className="nav-link navItm" to="/media" onClick={toggleNav}> MEDIA
                </NavLink>

                <NavLink className="nav-link navItm" to="/feedback" onClick={toggleNav}> FEEDBACK
                </NavLink>


                <div className="nav-link navItm" onClick={() => {
                  window.open('https://iopscience.iop.org/article/10.3847/1538-3881/ad216a', '_blank');
                }}> FIRMAMENTO PAPER &nbsp;<i className="fas fa-external-link-alt" />
                </div>
                <div className="nav-link navItm mytooltipxx" onClick={() => {
                  window.open('https://mmdc.am/', '_blank');
                }}> MMDC &nbsp;<i className="fas fa-external-link-alt" />
                  <span className="tooltiptextxwfdsf">Go to Markarian Multi-wavelength Data Center</span>

                </div>
              </Nav>


            </Collapse>
            <p className='aiButton' onClick={handleDrawerOpen}>
              Firmamento AI
            </p>

            <NavLink className="nav-link navItm" to="/login" onClick={toggleNav}> {userName}
            </NavLink>



          </div>
        </Navbar>
      </div>

      <main>
        {props.children}
      </main>

      <footer className="mfooter">
        {/* upper section of footer  */}
        <div className="footuppr">
          <div className="container">
            <div className="row">
              {/* column 1 of footer  */}
              <div className="col-md-5 footerbrand">
                <img className="capthlogo" src={logoImg2} alt="cap3 logo"></img>
                <p>The Center for Astrophysics and Space Science is an alliance of faculty and scholars dedicated to research in astronomy, astrophysics, planetary and astroparticle physics.</p>
                <span className="icon-envelope-open"></span>
                <a className="hrefTxtt" target="_blank" rel="noopener noreferrer" href="https://nyuad.nyu.edu/en/research/faculty-labs-and-projects/center-for-astro--particle-and-planetary-physics.html">Visit</a>

              </div>

              {/* column 2 of footer  */}
              <div className="col-md-3 footerlnks">
                <h4>Quick Links</h4>
                <ul>
                  <li><NavLink to="/home" >Home</NavLink></li>
                  <li><NavLink to="/data_access" >Software</NavLink></li>
                  <li><NavLink to="/resources" >Resources</NavLink></li>
                  <li><span className='ackFirm' onClick={() => {
                    window.open("https://mmdc.am/", '_blank');
                  }}> MMDC &nbsp;<i className="fas fa-external-link-alt" /></span></li>
                  <li><span className='ackFirm' onClick={() => {
                    window.open('https://iopscience.iop.org/article/10.3847/1538-3881/ad216a', '_blank');
                  }}>Firmamento Paper &nbsp;<i className="fas fa-external-link-alt" /></span></li>
                  <li><span className="ackFirm" onClick={() => { setModelOpen(true); }}>Acknowledging Firmamento</span></li>
                </ul>
              </div>

              {/* column 3 of footer  */}
              <div className="col-md-4 footerns">
                <h4>Newsletter</h4>
                <p>Signup for new updates info and more resources </p>

                <div className="input-group">
                  <input type="text" className="form-control" placeholder="Email" />

                  <Button className="envBtn" color="success"><i className="far fa-envelope fa-lg"></i></Button>


                </div>

              </div>
            </div>
          </div>
        </div>
        <div className="footdown">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="copyright-text">
                  <a href="https://nyuad.nyu.edu">
                    <img src={adlogo} alt="Nyuad logo" width="150" height="60" ></img>
                  </a>
                  <p> &copy; {new Date().getFullYear()}
                    <NavLink to="/" > NYU Abu Dhabi</NavLink>
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>

      </footer>
      <Modal isOpen={isModelOpen} className="modal-dialog-centered col-12" >
        <ModalBody>
          <div>
            If firmamento was useful for your research an acknowledgment like the following
            in your paper would be appreciated.
            <br />
            <br />
            <b>
              This research made use of Firmamento, a tool developed at The Center for Astrophysics and Space Science (CASS) and at the office of Research Outreach of
              the New York University Abu Dhabi, in the framework of the Open Universe
              initiative. <a href='https://iopscience.iop.org/article/10.3847/1538-3881/ad216a' >(Tripathi et al. 2024, AJ 167 116)</a>
            </b>

          </div>


        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => {
              setModelOpen(false);
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >

        <div className="drawCloseBtn fixithereP" onClick={handleDrawerClose}>
          <ChevronRightIcon />
        </div>
        <div className='contentHoldrDrw'>
          <FirmamentoAi />
        </div>
      </Drawer>
    </Fragment >
  );
};

export default Layout;